export const getCriteriaFields = ({ labels, display, tripTypes }, tripEvents, vehicleTypes, cff) => {
  const genFields = [
    {
      label: labels.overnightOOS,
      check: 'outOfState === 1',
    },
    {
      label: labels.outOfCounty,
      check: 'outOfCounty === 1',
    },
  ];

  if (display.substitute)
    genFields.push({
      label: 'Substitute Needed',
      check: 'needSubstitute === 1',
    });
  if (display.newDestination)
    genFields.push({
      label: 'Destination not in Common Destinations Table',
      check: 'newDestination',
    });
  if (Array.isArray(display.healthConcerns) && display.healthConcerns.some((e) => tripTypes.includes(e)))
    genFields.push({
      label: labels.healthConcerns,
      check: 'haveHealthConcerns === 1',
    });
  if (display.extTransportation)
    genFields.push({
      label: labels.extTransportation,
      check: 'needExternalTransportation === 1',
    });
  if (Array.isArray(display.awayForLunch) && display.awayForLunch.some((e) => tripTypes.includes(e)))
    genFields.push({
      label: 'Students away for lunch',
      check: 'awayForLunch === 1',
    });
  genFields.push({
    label: 'Wheelchair lift required',
    check: 'needSpecialNeedsVehicle === 1',
  });
  const generalGroup = {
    heading: 'General',
    fields: genFields,
  };

  const tripEventsGroup = {
    heading: 'Trip Events',
    fields: tripEvents
      .filter((e) => tripTypes.includes(e.tripTypeId))
      .map((e) => ({
        prependLabel: 'Trip Event: ',
        label: e.name,
        check: `tripEventIds.includes(${e.id})`,
      })),
  };

  const vehicleTypesGroup = {
    heading: 'Vehicle Types',
    fields: vehicleTypes.map((e) => ({
      prependLabel: 'Vehicle Type: ',
      label: e.name,
      check: `vehicleTypeId === ${e.id}`,
    })),
  };

  const gradesGroup = {
    heading: 'Grades',
    fields: display.gradeLevel
      ? [
          {
            label: 'Pre-K',
            check: "gradeLevels.includes('Pre-K')",
          },
          {
            label: 'K',
            check: "gradeLevels.includes('K')",
          },
          {
            label: '1st Grade',
            check: 'gradeLevels.includes(1)',
          },
          {
            label: '2nd Grade',
            check: 'gradeLevels.includes(2)',
          },
          {
            label: '3rd Grade',
            check: 'gradeLevels.includes(3)',
          },
          {
            label: '4th Grade',
            check: 'gradeLevels.includes(4)',
          },
          {
            label: '5th Grade',
            check: 'gradeLevels.includes(5)',
          },
          {
            label: '6th Grade',
            check: 'gradeLevels.includes(6)',
          },
          {
            label: '7th Grade',
            check: 'gradeLevels.includes(7)',
          },
          {
            label: '8th Grade',
            check: 'gradeLevels.includes(8)',
          },
          {
            label: '9th Grade',
            check: 'gradeLevels.includes(9)',
          },
          {
            label: '10th Grade',
            check: 'gradeLevels.includes(10)',
          },
          {
            label: '11th Grade',
            check: 'gradeLevels.includes(11)',
          },
          {
            label: '12th Grade',
            check: 'gradeLevels.includes(12)',
          },
        ]
      : [],
  };

  const fundingSourcesGroup = {
    heading: 'Funding Sources',
    fields: [
      {
        label: 'Funding Source',
        check: 'fundingSources.length > 0',
      },
    ],
  };

  const customFieldsGroup = {
    heading: 'Custom Form Fields',
    fields: cff
      .filter((e) => e.type == 3)
      .map((e) => ({
        prependLabel: 'Custom Field: ',
        label: e.name,
        check: e.id,
        custom: true,
      })),
  };

  return [generalGroup, tripEventsGroup, vehicleTypesGroup, gradesGroup, fundingSourcesGroup, customFieldsGroup];
};

export const getComparisonFields = (customFields) => {
  const cff = customFields.filter((e) => e.type == 1 || e.type == 2);
  const comparisons = [
    { label: 'Trip Mileage', check: 'distance' },
    { label: '# Vehicles Requested', check: 'numVehicles' },
    { label: '# Students', check: 'totalStudents' },
    { label: '# Adults', check: 'totalAdults' },
    { label: 'Depart Date', check: 'leaveDate' },
    { label: 'Return Date', check: 'returnDate' },
  ];
  cff.forEach((e) =>
    comparisons.push({
      prependLabel: 'Custom Field: ',
      label: e.name,
      check: e.id,
      custom: true,
      date: e.type == 2,
    })
  );
  return comparisons;
};

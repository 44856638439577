<template>
  <div>
    <v-row>
      <v-col cols="12" md="3">
        <v-card>
          <v-card-title>
            Approval Levels

            <v-spacer></v-spacer>

            <v-row v-if="me.is.superAdmin" align="center" justify="end" no-gutters>
              <v-btn
                @click="setBulkDelete()"
                class="mr-4"
                color="error"
                dense
                text
                elevation="0"
                v-if="!isBulkDelete && items.length"
              >
                <v-icon left>mdi-delete</v-icon>
                Bulk Delete
              </v-btn>

              <v-btn @click="createItem()" v-if="!isBulkDelete" elevation="2" color="primary" fab small>
                <v-icon>mdi-plus</v-icon>
              </v-btn>

              <v-row v-if="isBulkDelete" align="center" justify="end" no-gutters>
                <v-btn @click="isBulkDelete = false" class="mr-2" text dense>
                  <v-icon left>mdi-cancel</v-icon>
                  Cancel
                </v-btn>

                <v-btn @click="bulkDelete()" color="error" elevation="0" dense>
                  <v-icon left>mdi-delete</v-icon>
                  Delete Bulk
                </v-btn>
              </v-row>
            </v-row>
          </v-card-title>

          <v-list shaped>
            <div class="text-center" v-if="approvalLevelsLoading">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div>

            <v-list-item-group color="primary" v-else>
              <draggable :disabled="!me.is.superAdmin || isBulkDelete" @sort="handleSort" v-model="items">
                <v-list-item v-for="(level, i) in items" :key="i" @click="editItem(level)">
                  <v-checkbox
                    :label="level.name"
                    :value="level.id"
                    v-if="isBulkDelete"
                    v-model="idsToDelete"
                  ></v-checkbox>

                  <v-list-item-icon v-if="!isBulkDelete">
                    <v-icon>mdi-drag-horizontal-variant</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content v-if="!isBulkDelete">
                    <v-list-item-title>{{ level.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </draggable>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>

      <v-col cols="12" md="9">
        <div class="d-flex justify-space-between mb-2" v-if="me.is.superAdmin && showable">
          <v-btn
            @click="
              editedItem = {};
              showable = false;
            "
          >
            Cancel
          </v-btn>
          <div>
            <v-btn class="mx-2" dark color="success" @click="saveItem()" v-show="editedItem.name"> Save </v-btn>
            <v-btn class="mx-2" dark color="error" @click="deleteItem()" v-show="editedItem.id"> Delete </v-btn>
          </div>
        </div>

        <v-card v-show="showable">
          <v-form :readonly="editedItem.id > 0 && !me.is.superAdmin && !me.is.transportationAdmin">
            <v-card-title class="content-title">
              <span class="text-h5">{{ editedItem.id ? 'Edit Approval Level' : 'New Approval Level' }}</span>
              <v-badge color="error" content="New" offset-x="0" :value="!editedItem.id"></v-badge>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" md="10">
                    <v-text-field :disabled="!isFieldEnabled" v-model="editedItem.name" label="Name"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6">
                    <v-radio-group :disabled="!isFieldEnabled" v-model="editedItem.incOvernightOOS" row mandatory>
                      <template v-slot:label>
                        <div>Include Overnight / OOS Trips?</div>
                      </template>
                      <v-radio label="Yes" :value="1"></v-radio>
                      <v-radio label="No" :value="0"></v-radio>
                    </v-radio-group>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-radio-group :disabled="!isFieldEnabled" v-model="editedItem.assignBusses" row mandatory>
                      <template v-slot:label>
                        <div>Required for Vehicle Assignment?</div>
                      </template>
                      <v-radio label="Yes" :value="1"></v-radio>
                      <v-radio label="No" :value="0"></v-radio>
                    </v-radio-group>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-radio-group :disabled="!isFieldEnabled" v-model="editedItem.boardReport" row mandatory>
                      <template v-slot:label>
                        <div>Board Report</div>
                      </template>
                      <v-radio label="Yes" :value="1"></v-radio>
                      <v-radio label="No" :value="0"></v-radio>
                      <v-radio label="OOS Only" :value="2"></v-radio>
                    </v-radio-group>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-radio-group :disabled="!isFieldEnabled" v-model="editedItem.notify" row mandatory>
                      <template v-slot:label>
                        <div>Notify Approver</div>
                      </template>
                      <v-radio label="Yes" :value="1"></v-radio>
                      <v-radio label="No" :value="0"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" md="12" dense>
                    <h5>Trip Types</h5>
                  </v-col>

                  <v-col cols="2">
                    <v-checkbox :disabled="!isFieldEnabled" v-model="areTripTypesAllSelected" label="All" />
                  </v-col>

                  <v-col cols="10">
                    <v-row>
                      <v-col cols="12" md="3" v-for="(type, i) in tripTypes" :key="i" dense>
                        <v-checkbox
                          :disabled="!isFieldEnabled"
                          v-model="editedItem.tripTypes"
                          :label="type.name"
                          :value="type.id"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="12">
                    <h5>Approval Criteria</h5>

                    <v-card class="ma-2 pa-2">
                      <v-card-text>
                        <v-row>
                          <template v-for="(criteria, criteriaIndex) in editedItem.criteria">
                            <v-chip
                              :key="criteriaIndex"
                              @click:close="deleteCriteria(criteriaIndex)"
                              class="ma-2 pa-2 full-chip"
                              :close="isFieldEnabled"
                              close-icon="mdi-delete"
                              large
                              v-if="!criteria.deleted"
                            >
                              {{ criteria.label }}
                            </v-chip>
                          </template>

                          <v-chip
                            @click="removeAllCriteria()"
                            class="ma-2 pa-2"
                            close
                            close-icon="mdi-close"
                            color="error"
                            large
                            v-if="editedItem.criteria && editedItem.criteria.length && isFieldEnabled"
                          >
                            Remove All
                          </v-chip>
                        </v-row>
                      </v-card-text>

                      <v-card-actions>
                        <v-chip label outlined small class="ma-2" color="info">
                          <v-icon small left>mdi-information-outline</v-icon>
                          Reminder: Click the 'Save' button to apply your changes.
                        </v-chip>

                        <v-spacer></v-spacer>

                        <v-btn :disabled="!isFieldEnabled" @click="newCriteria()" color="primary" text>
                          Edit Criteria
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row v-if="showApprovers">
                  <v-col cols="12" md="12">
                    <h5>Approvers</h5>

                    <v-card class="ma-2 pa-2">
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="12">
                            <v-chip
                              :close="me.is.superAdmin || me.is.transportationAdmin"
                              :key="i"
                              @click:close="deleteApproverFromLevel(approver.uuid)"
                              @click="editApprover(i)"
                              class="ma-2 pa-2"
                              close-icon="mdi-delete"
                              label
                              large
                              v-for="(approver, i) in editedItem.approvers"
                              v-show="!approver.delete"
                            >
                              <v-row class="w-90" dense>
                                <v-col cols="12">
                                  <p class="mb-0">
                                    {{ approver.userDisplayName ? approver.userDisplayName : 'Unregistered User' }}
                                  </p>
                                  <h5>{{ approver.userId ? approver.userEmail : '' }}</h5>
                                </v-col>

                                <v-col cols="12">
                                  <div class="text-overline mb-n4">Trip Types</div>
                                </v-col>

                                <v-col cols="12">
                                  <div class="d-flex flex-row flex-wrap">
                                    <div
                                      v-if="approver.tripTypeIds.length == tripTypes.length"
                                      class="text-caption d-inline-block mr-2"
                                    >
                                      All
                                    </div>
                                    <div v-else>
                                      <span
                                        class="text-caption d-inline-block mr-2"
                                        v-for="(tt, i) of approver.tripTypeIds"
                                        :key="i"
                                      >
                                        {{ tripTypesById[tt].name }}
                                      </span>
                                    </div>
                                  </div>
                                </v-col>

                                <v-col cols="12">
                                  <div class="text-overline mb-n4 mt-n2">Locations</div>
                                </v-col>

                                <v-col cols="12">
                                  <div class="d-flex flex-row flex-wrap">
                                    <div
                                      v-if="approver.locationIds.length == schools.length"
                                      class="text-caption d-inline-block mr-2"
                                    >
                                      All
                                    </div>

                                    <div v-else>
                                      <span
                                        class="text-caption d-inline-block mr-2"
                                        v-for="(l, i) of approver.locationIds"
                                        :key="i"
                                      >
                                        {{ locationsById[l]?.name }}
                                      </span>
                                    </div>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-chip>
                          </v-col>
                        </v-row>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          @click="editApprover()"
                          :disabled="
                            !editedItem.id || (editedItem.id > 0 && !me.is.superAdmin && !me.is.transportationAdmin)
                          "
                        >
                          Add Approver
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="acDialog" maxWidth="1000">
      <v-card>
        <v-card-text>
          <approver-creator
            :value="editedApprover"
            :approvalLevelId="editedItem.id"
            :users="users"
            :filteredTripTypes="editedItem.tripTypes"
            :refresh="refresh"
            :dialog="acDialog"
            :close="closeDialog"
            :readonly="!me.is.superAdmin && !me.is.transportationAdmin"
            isDialog
          ></approver-creator>
        </v-card-text>
      </v-card>
    </v-dialog>

    <criteria-builder :tripTypes="editedItem.tripTypes" ref="cbuilder" v-model="editedItem.criteria"></criteria-builder>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import draggable from 'vuedraggable';

import {
  GET_APPROVAL_LEVELS,
  SAVE_APPROVAL_LEVEL,
  REORDER_APPROVAL_LEVELS,
  DELETE_APPROVAL_LEVEL,
  DELETE_APPROVAL_LEVELS,
} from '@/store/modules/ApprovalLevel/actions';

import { DELETE_APPROVER } from '@/store/modules/Approver/actions';
import { GET_USERS } from '@/store/modules/User/actions';
import CriteriaBuilder from '@/components/ApprovalLevels/CriteriaBuilder';
import ApproverCreator from '@/components/ApproverCreator';

export default {
  name: 'ApprovalLevel',
  inject: ['eventHub'],
  components: { CriteriaBuilder, ApproverCreator, draggable },
  data() {
    return {
      acDialog: false,
      approvalLevelsLoading: true,
      editedApprover: null,
      editedItem: { id: 0, name: '' },
      filteredDataList: [],
      idsToDelete: [],
      isBulkDelete: false,
      showable: false,
    };
  },
  computed: {
    ...mapGetters('approvalLevel', ['approvalLevels']),
    ...mapGetters('tripType', ['tripTypes', 'tripTypesById']),
    ...mapGetters('location', ['schools', 'locationsById']),
    ...mapGetters('user', ['users', 'me']),
    areTripTypesAllSelected: {
      get() {
        if (!this.editedItem || !this.editedItem.tripTypes) return false;
        return this.editedItem.tripTypes.length === this.tripTypes.length;
      },
      set(value) {
        if (!value) this.editedItem.tripTypes = [];
        else this.editedItem.tripTypes = this.tripTypes.map((tripType) => tripType.id);
      },
    },
    items: {
      get() {
        return this.$store.getters['approvalLevel/approvalLevels'];
      },
      set(value) {
        this.setApprovalLevels(value);
      },
    },
    showApprovers() {
      if (this.editedItem.criteria && this.editedItem.criteria.map((e) => e.label).includes('Funding Source'))
        return false;
      else return true;
    },
    isFieldEnabled() {
      return this.me.is.superAdmin;
    },
  },
  created() {
    this.refresh();
  },
  methods: {
    ...mapActions('approvalLevel', [
      GET_APPROVAL_LEVELS,
      SAVE_APPROVAL_LEVEL,
      REORDER_APPROVAL_LEVELS,
      DELETE_APPROVAL_LEVEL,
      DELETE_APPROVAL_LEVELS,
    ]),
    ...mapActions('approver', [DELETE_APPROVER]),
    ...mapMutations('approvalLevel', ['setApprovalLevels', 'resequenceApprovalLevels']),
    ...mapActions('user', [GET_USERS]),
    async refresh() {
      await this.getApprovalLevels();
      await this.getUsers();

      if (this.editedItem.id) this.editedItem = this.items.find((e) => e.id == this.editedItem.id);

      this.approvalLevelsLoading = false;
    },
    async handleSort({ oldIndex, newIndex }) {
      if (oldIndex != newIndex) {
        this.resequenceApprovalLevels(this.items);

        try {
          await this.reorderApprovalLevels(this.approvalLevels);
        } catch (error) {
          this.$myalert.error(error.message);
        }
      }
    },
    createItem() {
      this.showable = true;
      this.editedItem = {
        approvers: [],
        criteria: [],
        seq: this.items.length + 1,
        tripTypes: [],
      };
    },
    editItem(item) {
      if (this.isBulkDelete) return;

      this.showable = true;
      this.editedItem = JSON.parse(JSON.stringify(item));
    },
    async saveItem(quiet) {
      try {
        const r = await this.saveApprovalLevel(this.editedItem);

        if (r && r.id) {
          if (!quiet) this.$myalert.success('Approval Level saved');

          this.editedItem.id = r.id;

          await this.refresh();
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    async deleteItem() {
      const yes = await this.$myconfirm(
        'Are you sure you want to delete this Approval Level? This operation cannot be undone.'
      );
      if (yes) {
        const r = await this.deleteApprovalLevel(this.editedItem.id);

        if (!r.done) return;

        this.$myalert.success('Approval Level deleted');
        this.showable = false;
        this.editedItem = {};
        this.refresh();
      }
    },
    async bulkDelete() {
      if (!this.idsToDelete.length) {
        this.$myalert.error('Must select at least one level to delete');

        return;
      }

      try {
        await this.deleteApprovalLevels(this.idsToDelete);

        this.$myalert.success('Approval Levels Deleted');
        this.isBulkDelete = false;

        await this.refresh();
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    setBulkDelete() {
      this.showable = false;
      this.editedItem = {};
      this.isBulkDelete = true;
    },
    closeDialog() {
      this.acDialog = false;
    },
    newCriteria() {
      this.$refs.cbuilder.fetchItems();
      this.$refs.cbuilder.dialog = true;
    },
    addCriteria(newValue) {
      this.editedItem.criteria = newValue;
    },
    async deleteCriteria(criteriaIndex) {
      const ok = await this.$myconfirm('Are you sure you want to delete this criteria?');

      if (!ok) return;

      this.editedItem.criteria = this.editedItem.criteria.map((criteria, index) => {
        if (index === criteriaIndex) criteria.deleted = true;
        return criteria;
      });
    },
    async removeAllCriteria() {
      const ok = await this.$myconfirm('Are you sure you want to delete all criteria?');

      if (!ok) return;

      this.editedItem.criteria = this.editedItem.criteria.map((criteria) => {
        criteria.deleted = true;
        return criteria;
      });
    },
    editApprover(index) {
      this.editedApprover = index >= 0 ? this.editedItem.approvers[index] : {};
      this.acDialog = true;
    },
    async deleteApproverFromLevel(uuid) {
      const ok = await this.$myconfirm('Are you sure you want to delete this Approver?');

      if (!ok) return;

      try {
        this.$myalert.warning('Deleting Approver...');

        const r = await this.deleteApprover(uuid);

        if (r.done) this.$myalert.success('Approver deleted');

        this.refresh();
      } catch (e) {
        this.$myalert.error(e.message);
      }
    },
  },
};
</script>

<style>
.table-action {
  margin-left: 10px;
}
.criteria-dialog {
  overflow-y: hidden !important;
  max-width: 1200px !important;
  max-height: 700px !important;
}
.v-chip.v-size--large {
  height: fit-content;
  width: min-content;
  max-width: 500px;
}
.w-90 {
  max-width: 96%;
}
.full-chip {
  max-width: 100%;
  min-width: fit-content;
}
</style>
